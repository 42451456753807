import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { getFirestore, collection, getDocs, query, addDoc, where, orderBy } from "firebase/firestore";

export const firebaseApp = initializeApp({
    apiKey: "AIzaSyD_pfbBO7TU7FagWXpYSzAnVyOAwoxWuq0",
    authDomain: "reaction-time-game-bf980.firebaseapp.com",
    projectId: "reaction-time-game-bf980",
    storageBucket: "reaction-time-game-bf980.appspot.com",
    messagingSenderId: "863557781563",
    appId: "1:863557781563:web:df0eb66c2491e690b0b41e",
    measurementId: "G-LQW7JG0HNC"
})

export const auth = getAuth(firebaseApp)
const provider = new GoogleAuthProvider();

export const popupSignIn = async () => { 
    const result = await signInWithPopup(auth, provider)
    return result.user
}

export const logout = async () => {
    return await signOut(auth)
}

export const db = getFirestore(firebaseApp)

export const getReactionTimes = async (uid) => {
    const querySnapshot = await getDocs(query(collection(db, 'reactionTimes'), 
        where('uid', '==', uid), orderBy('timestamp', 'desc')))
    return querySnapshot.docs.map(doc => doc.data())
}

export const storeData = async (collectionName, data) => {
    await addDoc(collection(db, collectionName), data)
}