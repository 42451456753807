<template>
    <div style="height: 200px;">
        <div class="game-card" v-show="showBox" @click="clicked">
            <div>
                <p v-show="!showReactionTime"> Click me as fast as you can!</p>
                <p v-show="showReactionTime"> Reaction time: {{reactionTime }} ms</p>
            </div>
        </div>
        <div class="spinner" v-show="!showBox"></div>
    </div>
</template>

<script>

export default {
    name: 'GameCard',
    data() {
        return {
            initialDelay: 2000,
            reactionTime: 0,
            showBox: false,
            showReactionTime: false,
            timeout: {
                type: Object,
                default: {}
            },
            interval: {
                type: Object,
                default: {}
            },
        }
    },
    mounted() {
        this.showBox = false
        const delay = this.initialDelay + Math.random() * 4000
        setTimeout(() => {
            this.showBox = true
            this.interval = setInterval(() => {
                this.reactionTime += 10;
            }, 10)
        }, delay);
    },
    methods: {
        clicked() {
            clearInterval(this.interval)
            this.showReactionTime = true
            this.$emit('end', this.reactionTime)
        }
    }
}

</script>


<style>
    .game-card {
        height: 100%;
        padding: 10px;
        text-align: center;
        margin: 10px auto;
        background-color: yellow;
        cursor: pointer;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 20px;
        color: dark;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

</style>