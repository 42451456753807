<template>
    <p v-if="reactionTime" class="info-box" :class="{'success': result === 'Fast'}"> Your result: {{ result }} {{ reactionTime }} ms </p>
    <p v-if="reactionTime && !user" style="text-align: center; margin-bottom: 20px;"> Store your result? </p>
</template>

<script>

export default {
    name: 'GameResult',
    props: {
        user: {
            type: Object,
        },
        reactionTime: {
            type: Number,
            default: 0
        }
    },
    computed: {
        result() {
            if (this.reactionTime < 500) {
                return 'Fast';
            }
            return 'Slow';
        }
    }
}

</script>

<style>
    .info-box {
        text-align: center;
        background-color: #d9edf7;
        border: 1px solid #31708f;
        border-radius: 4px;
        padding: 15px;
        margin: 15px 0;
        color: #31708f;
        font-weight: bold;
    }

    .info-box.success {
        background-color: #dff0d8;
        border-color: #16931a;
        color: #16931a;
    }


</style>