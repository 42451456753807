<template>
  <div class="container">
    <div class="text-center">
      <h1> Reaction Time Game </h1>
      <p v-if="user"> Welcome {{ user.displayName }} <a href="#" @click.prevent="logout"> Logout </a> </p>
      <p> Test your reaction time and enjoy the fun!</p>
      <br>
      <button @click="start" :disabled="gameStarted" class="button primary-button"> Start </button>
    </div>
    <GameResult :reactionTime="reactionTime" :user="user" />
    <GameCard v-if="gameStarted" @boxShown="() => boxShown = true" @end="gameEnds"/>
    <FirebaseAuth @signedIn="signedIn" v-if="reactionTime" />
    <div style="margin-top: 20px">
      <ReactionTimeHistory v-if="!gameStarted && user"/>
      <div class="text-center" v-if="!gameStarted && !reactionTime && !user">
        <p> Sign in to show history. </p>
        <FirebaseAuth @signedIn="signedIn"/>
      </div>
    </div>
  </div>
</template>

<script>
import GameResult from "./components/GameResult.vue"
import GameCard from "./components/GameCard.vue"
import ReactionTimeHistory from "./components/ReactionTimeHistory.vue"
import FirebaseAuth from "./components/FirebaseAuth.vue"
import { storeData, logout } from "./firebase"
import { serverTimestamp } from "firebase/firestore"

export default {
  name: 'App',
  components: {
    FirebaseAuth,
    GameResult,
    GameCard,
    ReactionTimeHistory,
  },
  data() {
    return {
      gameStarted: false,
      reactionTime: 0,
      boxShown: false,
      user: null,
    }
  },
  methods: {
    start() {
      this.gameStarted = true
      this.result = ''
      this.reactionTime = 0
    },
    gameEnds(reactionTime) {
      this.gameStarted = false
      this.reactionTime = reactionTime

      // add data to firestore
      if (this.user) {
        this.storeReactiontime(reactionTime)
      }
    },
    signedIn(user) {
      this.user = user
    },
    async logout() {
      await logout()
      this.user = null
      this.reactionTime = 0
      localStorage.setItem('user', null)
    },
    async storeReactiontime(reactionTime) {
      try {
        await storeData('reactionTimes', {
          uid: this.user.uid,
          time: reactionTime,
          timestamp: serverTimestamp()
        })
      } catch (error) {
        console.log(error)        
      }
    }
  }
}
</script>

<style>
  .container {
    max-width: 500px;
    margin: 0 auto;
    margin: 10px auto;
    background-color: lightgray;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
    margin: 40px auto;
    transition: box-shadow 0.3s ease-in-out;
  }

  button:disabled {
    background-color: grey;
    cursor: default;
  }

  .text-center {
    text-align: center;
  }

  /* Reset some default styles */
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  /* Body styles */
  body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #333333;
    background-color: #f5f5f5;
  }

  /* Heading styles */
  h1 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  /* Text styles */
  p {
    font-size: 1rem;
  }

  /* Button styles */
  .button {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .primary-button {
    background-color: #3498db;
    color: #ffffff;
  }

  .secondary-button {
    background-color: #2ecc71;
    color: #ffffff;
  }

  .disabled-button {
    background-color: #e0e0e0;
    color: #a0a0a0;
    cursor: not-allowed;
  }

  /* Form styles */
  input,
  textarea {
    display: block;
    width: 100%;
    margin-bottom: 16px;
    padding: 8px;
    border: 1px solid #cccccc;
    border-radius: 4px;
  }

  .spinner-card {
    height: 400px;
    display: flex;
    align-items: center;
  }

  .spinner {
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: auto;
      margin-top: 50px;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
</style>