<template>
  <div>
    <p> History: </p>
    <table id="reactionTimesTable">
      <thead>
        <tr>
          <th>#</th>
          <th>Reaction time</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td>{{ index + 1 }}</td>
          <td>  {{ item.time }} ms </td>
          <td>{{ item.timestamp?.toDate().toLocaleString() }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import { getReactionTimes } from '@/firebase';
import { getCurrentUser } from 'vuefire';
export default {
  data() {
    return {
      data: []
    }
  },
  async created() {
    try {
      const user = await getCurrentUser()
      this.data = await getReactionTimes(user.uid)
    } catch (error) {
      console.log(error)
    }
  }
  
};
</script>


<style>

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  li:last-child {
    border-bottom: none;
  }

  table {
      border-collapse: collapse;
      width: 80%;
      margin: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      background-color: #fff;
    }

    th, td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }

    th {
      background-color: #54a2d7;
      color: white;
    }

    tr:hover {
      background-color: #f5f5f5;
    }

    #reactionTimesTable {
      font-size: 14px;
      margin: 0 auto;
      width: 100%;
      margin-top: 20px;
    }

</style>
