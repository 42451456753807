<template>
  <div class="text-center">
    <button v-if="!user" class="button secondary-button" @click.prevent="signIn"> SignIn </button>
  </div>
</template>

<script>

import { popupSignIn } from '@/firebase'

export default {
  data() {
    return {
      user: null
    }
  },
  methods: {
    async signIn() {
      try {
        const user = await popupSignIn();
        this.user = user
        localStorage.setItem('user', JSON.stringify(user))
        this.$emit('signedIn', user)
      } catch (error) {
        console.error('Error signing in:', error.message);
      }
    },
  },
  created() {
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'))
      this.$emit('signedIn', this.user)
    }
  }
};
</script>
